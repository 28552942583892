import React from 'react'
import Seo from '../components/seo'
import Callout from '../components/callout'
import { ReactComponent as Kubelinter } from '../images/kubelinter.svg'
import { Youtube, Slack, Twitter, Edit, GitHub } from 'react-feather'
import { graphql, useStaticQuery } from 'gatsby'

export default function CommunityPage() {
  const headingStyle =
    'font-headings text-3xl md:text-4xl lg:text-5xl text-base-800 mb-2 lg:mb-6'
  const paragraphStyle =
    'text-lg lg:text-xl text-base-600 pb-4 border-b border-base-300 mb- lg:h-full'

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            kubelinter
            socialMedia {
              platform
              url
            }
          }
        }
      }
    `,
  )

  // There must be a better way of doing this. If so, send suggestion.
  const twitter = site.siteMetadata.socialMedia.filter(
    (media) => media.platform === 'twitter',
  )[0].url

  return (
    <>
      <Seo title="Community" />
      <section className="light bg-primary-800 text-primary-200 flex flex-col justify-center items-center text-center min-h-[400px] px-4 md:px-6">
        <div className="space-y-6 max-w-[900px]">
          <h1 className="font-headings font-bold text-5xl md:text-6xl tracking-wide">
            Join the StackRox community
          </h1>
          <p className="text-lg lg:text-xl">
            StackRox is an open source project that anyone in the community can
            use, improve, and enjoy. We'd love you to join us! Here's a few ways
            to find out what's happening and get involved.
          </p>
        </div>
      </section>
      <div className="z-0 py-12 lg:pt-32 md:pb-40 relative dark:before:opacity-60 dark:after:opacity-60 before:scale-x-[-1] before:z-[-1] before:bg-wave-pattern before:bg-no-repeat before:text-transparent before:w-full after:z-[-1] before:absolute before:h-full before:top-0 before:bg-left-top after:text-transparent after:bg-wave-pattern after:rotate-180 after:bg-no-repeat after:w-full after:z-[-1] after:absolute after:h-full after:top-0 after:bg-left-top after:scale-x-[-1]">
        <div
          className="absolute w-full top-0 left-0 h-full pointer-events-none z-[-1]"
          aria-hidden
        >
          <div
            style={{ bottom: '110px', left: '10vw' }}
            className="bg-alert-100 dark:hidden absolute rounded-full h-8 w-8"
          />
          <div
            style={{ bottom: '30px', right: '50%' }}
            className="bg-warning-100 dark:hidden absolute rounded-full h-12 w-12"
          />
          <div
            style={{ bottom: '80px', right: '10vw' }}
            className="bg-primary-100 dark:hidden absolute rounded-full h-14 w-14"
          />
        </div>

        <div className="grid lg:grid-cols-2 my-12 md:my-0 gap-8 page-wrapper">
          <div className="flex flex-col h-full">
            <h2 className={headingStyle}>Join us!</h2>
            <p className={paragraphStyle}>
              Here are a few ways to get&nbsp;involved:
            </p>
          </div>

          <Callout
            className="lg:order-3"
            title="Community Meetings"
            url="https://www.youtube.com/playlist?list=PL_uKap2n977vGehd7Mkm-5qP3-zwgrdG9"
            icon={<Youtube alt="" />}
          >
            Join our monthly office hours to learn about Kubernetes
            security&nbsp;topics.
          </Callout>
          <Callout
            className="lg:order-5"
            title="Slack"
            url="/slack/"
            icon={<Slack alt="" />}
          >
            We regularly converse on Slack. Feel free to drop by and
            ask&nbsp;questions.
          </Callout>
          <Callout
            className="lg:order-7"
            title="Twitter"
            url={twitter}
            icon={<Twitter alt="" />}
          >
            You can get blog and event announcements on Twitter as well as our
            other&nbsp;channels.
          </Callout>

          <div className="mt-8 lg:mt-0 lg:order-2 flex flex-col h-full">
            <h2 className={headingStyle}>Contribute</h2>
            <p className={paragraphStyle}>
              As we move through the open sourcing of StackRox, there are many
              ways to&nbsp;contribute:
            </p>
          </div>

          <Callout
            title="Blogs"
            className="lg:order-6"
            type="warning"
            url="mailto:community@stackrox.io?subject=Blog%20contribution&body=My%20blog%20topic%20is%20"
            icon={<Edit alt="" />}
          >
            Blogs are open for contribution. Is there a topic you are interested
            in? Let the organizers know about&nbsp;it.
          </Callout>
          <Callout
            className="lg:order-8"
            title="KubeLinter"
            type="warning"
            url={site.siteMetadata.kubelinter}
            icon={<Kubelinter alt="" />}
          >
            KubeLinter is an open source Kubernetes object linter. We would love
            for you to take it for a test run and learn more about
            the&nbsp;project.
          </Callout>
          <Callout
            className="lg:order-4"
            title="Star the Project on GitHub!"
            type="warning"
            url="https://github.com/stackrox/stackrox"
            icon={<GitHub alt="" />}
          >
            Use this repo to get started, submit issues and follow the project!
          </Callout>
        </div>
      </div>
    </>
  )
}
